import React from 'react';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';

// components and helpers
import hederaImage from '../assets/images/hedera-hashgraph-hbar-logo.png';
import acoerLogo from '../assets/images/acoer_logo_black.svg';
import InsightSideAiIcon from '../assets/images/Black&YellowAIIcon.svg';
import videoImage from '../assets/images/video_image.png';
import ComponentWrapper from '../containers/ComponentWrapper';

// mui
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { List, ListItem, ListItemAvatar, ListItemText, Paper } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const HomeView: React.FC = ({}) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    const bulletPoints = [
        'Organ Procurement Explorer provides a user-friendly platform offering real-time data visualization and streamlined search for organ donation and transplant data based on OPTN, SRTR and HRSA data.',
        'Supporting researchers, healthcare providers, policymakers, and anyone seeking comprehensive organ procurement information for effective decision-making.',
        'The platform delivers analytics that aid in understanding trends and addressing disparities in organ procurement, enhancing transparency and efficiency in the process.',
    ];
    return (
        <div
            style={{
                backgroundRepeat: 'no-repeat',
                minHeight: '100%',
                marginTop: 50,
            }}
        >
            <Grid container justifyContent={'center'} alignItems={'center'} spacing={3}>
                <Grid item xs={12} md={11}>
                    <Paper elevation={0} style={{ padding: 40, borderRadius: 15, paddingBottom: matches ? 400 : 0 }}>
                        <Grid container spacing={4} alignItems={'center'}>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant={'h4'} style={{ fontWeight: 'bold' }} color={'primary'}>
                                            Organ Data Explorer
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>
                                            Acoer's Organ Procurement Explorer is a user-friendly platform that provides
                                            real-time data visualization and streamlined search capabilities for organ
                                            donation and transplant information. The platform aggregates data from
                                            multiple sources, offering easy access to up-to-date information for
                                            informed decision-making.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <List>
                                            {bulletPoints.map((value: string, index: number) => {
                                                return (
                                                    <ListItem key={`homepage_bullet_${index}`}>
                                                        <ListItemAvatar>
                                                            <CheckCircleIcon color={'primary'} />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={
                                                                <Typography variant={'subtitle2'}>{value}</Typography>
                                                            }
                                                        />
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>
                                            Explore the dashboard or contact us for more information on accessing
                                            comprehensive organ procurement data.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{ marginBottom: matches ? 50 : 40 }}>
                                        <Link to={'/dashboard'} style={{ textDecoration: 'none', paddingRight: 10 }}>
                                            <Button
                                                variant={'contained'}
                                                color={'primary'}
                                                style={{
                                                    fontWeight: 'bold',
                                                    borderRadius: 30,
                                                }}
                                            >
                                                View Dashboard
                                            </Button>
                                        </Link>
                                        <a
                                            href="https://www.acoer.com/company/contact"
                                            target={'_blank'}
                                            rel="noreferrer"
                                            style={{ textDecoration: 'none' }}
                                        >
                                            <Button
                                                variant={'outlined'}
                                                color={'primary'}
                                                style={{
                                                    fontWeight: 'bold',
                                                    borderRadius: 30,
                                                    marginLeft: 5,
                                                }}
                                            >
                                                Contact Us
                                            </Button>
                                        </a>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6} style={{ position: 'relative' }}>
                                <ReactPlayer
                                    url={'https://vimeo.com/960684509'}
                                    light={videoImage}
                                    controls={true}
                                    style={{
                                        borderRadius: '50%',
                                        display: 'block',
                                        margin: 'auto',
                                        maxWidth: '100%',
                                        position: matches ? 'absolute' : 'relative',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={11}>
                    <Paper elevation={0} style={{ padding: 40, borderRadius: 15 }}>
                        <Grid container justifyContent={'center'} direction={'column'} alignItems={'center'}>
                            <Grid container justifyContent={'center'}>
                                <Grid item xs={12}>
                                    <Grid container alignItems={'center'} spacing={3}>
                                        <Grid item>
                                            <img
                                                src={InsightSideAiIcon}
                                                alt="Hedera Hashgraph"
                                                style={{ width: 75, borderRadius: '0%' }}
                                            />
                                        </Grid>
                                        <Grid item md={10} xs={12}>
                                            <Typography variant={'body1'}>
                                                Discover how our AI Companion for Organ Procurement is transforming the
                                                way we explore organ donation and transplantation data, focusing on
                                                integrity and empowering users.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={8} xs={11} style={{ marginTop: 30 }}>
                                <Typography variant={'body1'}>
                                    This AI tool enhances how you find and understand organ procurement data by using
                                    advanced algorithms to deliver the most relevant insights. Its natural language
                                    processing feature lets you interact with the AI conversationally, making your data
                                    exploration intuitive and accessible. The user-friendly interface ensures you can
                                    access critical information quickly and easily.
                                </Typography>
                            </Grid>
                            <Grid item md={8} xs={11} style={{ marginTop: 30 }}>
                                <Typography variant={'body1'}>
                                    What sets this tool apart is its commitment to transparency and accountability
                                    through ethical AI. By leveraging secure data stamping and lineage tracking, every
                                    AI-driven insight is recorded with integrity, ensuring the process is transparent
                                    and trustworthy. Experience the power of ethical AI with our Organ Procurement AI
                                    Companion!
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={11}>
                    <Paper elevation={0} style={{ padding: 40, borderRadius: 15 }}>
                        <Grid container justifyContent={'center'} direction={'column'} alignItems={'center'}>
                            <Grid container justifyContent={'center'}>
                                <Grid item xs={12}>
                                    <Grid container alignItems={'center'} spacing={3}>
                                        <Grid item>
                                            <img
                                                src={hederaImage}
                                                alt="Hedera Hashgraph"
                                                style={{ width: 75, borderRadius: '0%' }}
                                            />
                                        </Grid>
                                        <Grid item md={10} xs={12}>
                                            <Typography variant={'body1'}>
                                                Acoer uses{' '}
                                                <a
                                                    href="https://hedera.com/how-it-works"
                                                    target={'_blank'}
                                                    rel="noreferrer"
                                                >
                                                    Hedera
                                                </a>{' '}
                                                to publicly verify data changes. Each time Cancer Trials data changes,
                                                we retrieve the latest dataset and simultaneously{' '}
                                                <a href="https://stamp.hashlog.io/" target={'_blank'} rel="noreferrer">
                                                    Data Stamp
                                                </a>{' '}
                                                it to transparently prove the most current state of the data.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={8} xs={11} style={{ marginTop: 30 }}>
                                <Typography variant={'body1'}>
                                    All cancer trials data are consumed and stored in our data lake while also
                                    leveraging the power and security of{' '}
                                    <a href="https://hedera.com/" target={'_blank'} rel="noreferrer">
                                        Hedera Hashgraph
                                    </a>
                                    , a Distributed Ledger Technology (DLT), in order to provide a layer of confidence
                                    for the user viewing the trials. A timestamped hash of each clinical trial dataset
                                    stored on Hedera Hashgraph to ensure data provenance which proves what we are
                                    showing on the dashboard is exactly what is provided by the U.S. National Library of
                                    Medicine, allows us to audit all information captured while having the ability to
                                    prove where it originated, who authored it, as well as if it has been modified
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent={'center'} alignItems={'center'} spacing={3} direction={'column'}>
                        <Grid item>
                            <a
                                href="https://www.acoer.com/"
                                target={'_blank'}
                                rel="noreferrer"
                                style={{ textDecoration: 'none' }}
                            >
                                <img src={acoerLogo} alt="Acoer" height={50} />
                            </a>
                        </Grid>
                        <Grid item>
                            <Typography variant={'body1'}>Copyright © 2024 | All Rights Reserved</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default ComponentWrapper(HomeView);
