import React from 'react';

// components and helpers
import Notification from '../components/Notifications/Notification';
import Confirmation from '../components/Confirmation/Confirmation';
import Navbar from '../components/NavBar/NavBar';
import AppProvider from './AppProviderWrapper';
import backgroundImage from '../assets/images/NewCTEBackground.png';
import dashboardBackgroundImage from '../assets/images/ThinBannerForCTEDashboard.svg';
// mui
import Box from '@mui/material/Box';
import { CssBaseline } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import DashboardExportSnackbar from '../components/Dashboard/DashboardExportSnackbar';

function ComponentWrapper<T>(WrappedComponent: React.ComponentType<T>): (props: T) => JSX.Element {
    const ComponentWithWrapper = (props: T) => {
        const theme = useTheme();
        const matches = useMediaQuery(theme.breakpoints.down('md'));
        const url = window.location.href;
        const dashboardView = url.includes('dashboard');
        return (
            <AppProvider>
                <Box
                    sx={{
                        display: matches ? 'grid' : 'flex',
                    }}
                >
                    <div
                        style={{
                            backgroundColor: '#0b0b0b',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            maxHeight: dashboardView ? (matches ? 400 : 75) : 400,
                            width: '100%',
                        }}
                    >
                        <CssBaseline />
                        <Navbar dashboardView={dashboardView}>
                            {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                            {/*@ts-ignore*/}
                            <WrappedComponent {...props} />
                        </Navbar>
                    </div>
                </Box>
                <Notification />
                <Confirmation />
                <DashboardExportSnackbar />
            </AppProvider>
        );
    };

    return ComponentWithWrapper;
}

export default ComponentWrapper;
