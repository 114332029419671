import React from 'react';

// components and helpers
import logo from '../assets/images/NewOrganDataLogo.svg';
import organDataGraphic from '../assets/images/OrganDataGraphic.png';

// mui
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import IconButton from '@mui/material/IconButton';
import { CardActionArea, FormControl, OutlinedInput } from '@mui/material';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const HomeView: React.FC = ({}) => {
    const [questionInput, setQuestionInput] = React.useState<string>('');
    const promptQuestions = [
        'What are the most recent trends in organ transplants by demographic groups (e.g., age, gender, ethnicity)?',
        'Can you provide a comparison of organ procurement rates across different OPOs?',
        'Can you analyze the trends in kidney transplant waitlist numbers over the past few years?',
    ];

    const handleSend = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            window.open('https://chat.organdata.org/', '_blank');
        }
    };

    return (
        <div
            style={{
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundColor: '#0b0b0b',
                width: '100%',
                minHeight: '100vh', // This ensures that the background extends even with scrolling content
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
        >
            <Grid container justifyContent={'space-between'} alignItems={'flex-end'} sx={{ p: 4 }}>
                <Grid item>
                    <img src={logo} alt="organ-data-logo" style={{ maxWidth: '70%' }} />
                </Grid>
                <Grid item>
                    <Button
                        variant={'outlined'}
                        color={'primary'}
                        href={'/about'}
                        style={{ marginRight: 10, color: '#fff', borderColor: '#fff' }}
                    >
                        About OrganData.org
                    </Button>
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        href={'/dashboard/overview'}
                        style={{ color: '#0b0b0b' }}
                    >
                        Analytics Dashboard
                    </Button>
                </Grid>
            </Grid>

            <Grid container justifyContent={'space-evenly'} alignItems={'center'} sx={{ p: 6, flexGrow: 1 }}>
                <Grid item xs={6}>
                    <Grid container spacing={6}>
                        <Grid item>
                            <h1 style={{ color: 'white', fontWeight: 'bold' }}>
                                How can we support your organ journey?
                            </h1>
                        </Grid>
                        <Grid item>
                            <h4 style={{ color: 'white' }}>
                                Access the latest insights and trends in organ donation and transplantation to empower
                                informed decision-making.
                            </h4>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant="filled" fullWidth>
                                <OutlinedInput
                                    sx={{
                                        width: '100%',
                                        backgroundColor: '#fff !important',
                                        fontSize: '0.8rem',
                                    }}
                                    id="outlined-prompt"
                                    placeholder={'Type your question here'}
                                    margin="none"
                                    onKeyUp={handleSend}
                                    value={questionInput}
                                    onChange={(e) => setQuestionInput(e.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                onClick={() => window.open('https://chat.organdata.org/', '_blank')}
                                            >
                                                <SendRoundedIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <Grid container spacing={1} sx={{ mt: 1 }}>
                                    {promptQuestions.map((question, index) => (
                                        <Grid item xs={4} key={index}>
                                            <Card sx={{ maxWidth: '100%', minHeight: 100 }}>
                                                <CardActionArea onClick={() => setQuestionInput(question)}>
                                                    <CardContent>
                                                        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                                                            {question}
                                                        </Typography>
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <img src={organDataGraphic} alt="organ-data-logo" style={{ maxHeight: '400px' }} />
                </Grid>
            </Grid>

            <Grid container justifyContent={'center'} sx={{ p: 4 }}>
                <Grid item>
                    <Typography style={{ color: 'white', textAlign: 'center' }} variant={'h6'}>
                        Built by{' '}
                        <a
                            href={'https://cryptographicdatamesh.com/'}
                            target={'_blank'}
                            style={{
                                fontWeight: 'bold',
                                textDecoration: 'none',
                                boxShadow: '0px 3px 0px 0px #FFDB58',
                                color: '#fff',
                            }}
                        >
                            Acoer Cryptographic Data Mesh
                        </a>
                        , powered by organ procurement and transplant data from{' '}
                        <a
                            href={'https://optn.transplant.hrsa.gov/'}
                            target={'_blank'}
                            style={{
                                fontWeight: 'bold',
                                textDecoration: 'none',
                                boxShadow: '0px 3px 0px 0px #FFDB58',
                                color: '#fff',
                            }}
                        >
                            OPTN
                        </a>
                        ,{' '}
                        <a
                            href={'https://www.srtr.org/'}
                            target={'_blank'}
                            style={{
                                fontWeight: 'bold',
                                textDecoration: 'none',
                                boxShadow: '0px 3px 0px 0px #FFDB58',
                                color: '#fff',
                            }}
                        >
                            SRTR
                        </a>
                        , and{' '}
                        <a
                            href={'https://www.hrsa.gov/'}
                            target={'_blank'}
                            style={{
                                fontWeight: 'bold',
                                textDecoration: 'none',
                                boxShadow: '0px 3px 0px 0px #FFDB58',
                                color: '#fff',
                            }}
                        >
                            HRSA
                        </a>
                        , with{' '}
                        <a
                            href={'https://www.acoer.com/news/blog/ethical-ai-in-healthcare'}
                            target={'_blank'}
                            style={{
                                fontWeight: 'bold',
                                textDecoration: 'none',
                                boxShadow: '0px 3px 0px 0px #FFDB58',
                                color: '#fff',
                            }}
                        >
                            Ethical AI-driven
                        </a>{' '}
                        insights.
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
};

export default HomeView;
