import React, { useContext, useEffect } from 'react';
import Dashboard from '../components/Dashboard/Dashboard';
import { DashboardContext } from '@acoer/ac-js-lib-charts';
import { Grid } from '@mui/material';
import ComponentWrapper from '../containers/ComponentWrapper';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AppContext } from '../containers/AppProviderWrapper';

const DashboardView: React.FC = ({}) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const [dashboardId, setDashboardId] = React.useState<string>('organ-procurement');
    const { selectedDashboard } = useContext(AppContext);

    useEffect(() => {
        if (selectedDashboard === 'Grants') {
            setDashboardId('award-grant-explorer');
        } else {
            setDashboardId('organ-procurement');
        }
    }, [selectedDashboard]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <Grid container id={matches ? '' : 'workspace-analytics'} sx={{ mt: 8 }}>
            <DashboardContext.Provider value={{ dashboardId: dashboardId }}>
                <Dashboard />
            </DashboardContext.Provider>
        </Grid>
    );
};

export default ComponentWrapper(DashboardView);
